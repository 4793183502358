@media (min-width: 991px) {
  .menu-step {
    display: none;
  }
}
@media (max-width: 991px) {
  
  .menu-step {
    background-color: transparent;
    bottom: 2px;
    color: var(--interactive);
    content: "\f7a4";
    display: block;
    font-family: "Font Awesome 5 Free", sans-serif;
    font-size: var(--switch-icon-size);
    font-weight: var(--font-weight-black);
    height: 1em;
    left: 50%;
    margin-left: -0.5em;
    position: absolute;
    top: unset;
    transition-delay: 0s;
    transition-duration: 0.25s;
    transition-property: all;
    transition-timing-function: linear;
    width: auto;
  }
  .menu-step::after {
    content: "\f7a4";
  }
}

@media (max-width: 991px) {
  .hidden-step {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  }
  .hidden-father {
    min-height: 90px !important;
    max-height: 90px !important;
  }
  .hidden-button {
    margin-top: 0% !important;
  }
  .hidden-connector {
    top: 25% !important;
  }
}