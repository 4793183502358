.br-breadcumb {
  font-size: var(--font-size-scale-down-01);
  font-weight: var(--font-weight-medium);
  height: var(--spacing-scale-3x);
  position: relative;
}

@media (max-width: 450px) {
  .break-text {
    word-break: break-word;
    white-space: normal;
  }
}