.cookie-fs-modal-cookie-site-experience-value {
  display: flex;
  align-items: center;
}

.cookie-fs-modal-cookie-site-experience-title {
  font-size: var(--font-size-scale-up-01);
  font-weight: 600;
  font-weight: var(--font-weight-bold);
}

.cookie-fs-modal-cookie-paragraph-no-margin {
  margin: 0;
}

.cookie-fs-modal-title {
  font-size: 28px !important;
}

@media (max-width: 768px) {
  .cookie-fs-modal-title {
    font-size: 20px !important;
  }
}

.br-switch {
  --switch-height: var(--switch-height-medium);
  --switch-width: var(--switch-width-medium);
  --switch-toggle-size: var(--switch-toggle-medium);
  --switch-icon-size: var(--switch-icon-medium);
  --switch-height-small: 24px;
  --switch-height-medium: 30px;
  --switch-height-large: 36px;
  --switch-width-small: 40px;
  --switch-width-medium: 52px;
  --switch-width-large: 64px;
  --switch-toggle-small: 16px;
  --switch-toggle-medium: 22px;
  --switch-toggle-large: 28px;
  --switch-icon-small: var(--icon-size-xs);
  --switch-icon-medium: var(--icon-size-sm);
  --switch-icon-large: var(--icon-size-base);
  display: inline-flex;
  min-height: var(--switch-height);
  /* stylelint-disable no-descending-specificity */
  /* stylelint-enable no-descending-specificity */
}
.br-switch input {
  opacity: 0;
  position: absolute;
}
.br-switch input + label {
  align-items: center;
  color: var(--color);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font-size-scale-base);
  margin-bottom: 0;
  position: relative;
}
.br-switch input + label::before {
  background: var(--background-light);
  border: 1px solid var(--border-color);
  border-radius: 100em;
  content: "";
  height: var(--switch-height);
  position: absolute;
  width: var(--switch-width);
}
.br-switch input + label::after {
  background-color: var(--off);
  border-radius: 50%;
  content: "";
  height: var(--switch-toggle-size);
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: var(--switch-toggle-size);
}
.br-switch input:checked + label::after {
  background-color: var(--on);
}
.br-switch input + label {
  padding-right: calc(var(--spacing-scale-2x) + var(--switch-width));
}
.br-switch input + label:empty {
  padding-right: var(--switch-width);
}
.br-switch input + label::before {
  right: 0;
}
.br-switch input + label::after {
  right: calc(4px + var(--switch-toggle-size));
}
.br-switch input:checked + label::after {
  right: 4px;
}
.br-switch.right input + label {
  padding-left: calc(var(--spacing-scale-2x) + var(--switch-width));
  padding-right: 0;
}
.br-switch.right input + label::before {
  left: 0;
}
.br-switch.right input + label::after {
  left: 4px;
}
.br-switch.right input:checked + label::after {
  left: calc(var(--switch-width) - var(--switch-toggle-size) - 4px);
}
.br-switch.top {
  min-height: calc(var(--switch-height) * 2);
}
.br-switch.top input + label {
  align-items: flex-start;
  padding-right: 0;
}
.br-switch.top input + label::before {
  bottom: 0;
  left: 0;
}
.br-switch.top input + label::after {
  bottom: 4px;
  left: 4px;
}
.br-switch.top input:checked + label::after {
  left: calc(var(--switch-width) - var(--switch-toggle-size) - 4px);
}
.br-switch.small {
  --switch-height: var(--switch-height-small);
  --switch-width: var(--switch-width-small);
  --switch-toggle-size: var(--switch-toggle-small);
  --switch-icon-size: var(--switch-icon-small);
}
.br-switch.medium {
  --switch-height: var(--switch-height-medium);
  --switch-width: var(--switch-width-medium);
  --switch-toggle-size: var(--switch-toggle-medium);
  --switch-icon-size: var(--switch-icon-medium);
}
.br-switch.large {
  --switch-height: var(--switch-height-large);
  --switch-width: var(--switch-width-large);
  --switch-toggle-size: var(--switch-toggle-large);
  --switch-icon-size: var(--switch-icon-large);
}
.br-switch input ~ .switch-data {
  align-self: center;
  color: var(--color);
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-regular);
  margin-left: 8px;
}
.br-switch input ~ .switch-data::before {
  content: attr(data-disabled);
}
.br-switch input:checked ~ .switch-data {
  color: var(--color);
}
.br-switch input:checked ~ .switch-data::before {
  content: attr(data-enabled);
}
.br-switch.icon input + label::after {
  align-items: center;
  color: var(--color-dark);
  content: "\f00d";
  display: inline-flex;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-size: var(--switch-icon-size);
  font-weight: var(--font-weight-black);
  justify-content: center;
}
.br-switch.icon input:checked + label::after {
  content: "\f00c";
}
.br-switch input:focus-visible + label::before,
.br-switch input.focus-visible + label::before {
  border-color: var(--focus) !important;
  box-shadow: 0 0 0 var(--surface-width-md) var(--focus);
  outline: none;
}
.br-switch input:hover:not([disabled]):checked + label::before {
  background-image: linear-gradient(rgba(var(--on-rgb), var(--hover)), rgba(var(--on-rgb), var(--hover)));
}
.br-switch input:hover:not([disabled]):not(:checked) + label::before {
  background-image: linear-gradient(rgba(var(--off-rgb), var(--hover)), rgba(var(--off-rgb), var(--hover)));
}
.br-switch input:active:not([disabled]):checked + label::before {
  background-image: linear-gradient(rgba(var(--on-rgb), var(--pressed)), rgba(var(--on-rgb), var(--pressed)));
}
.br-switch input:active:not([disabled]):not(:checked) + label::before {
  background-image: linear-gradient(rgba(var(--off-rgb), var(--pressed)), rgba(var(--off-rgb), var(--pressed)));
}
.br-switch.inverted,
.br-switch.inverted label, .br-switch.dark-mode,
.br-switch.dark-mode label {
  color: var(--color-dark);
}

/*# sourceMappingURL=switch.css.map*/
